<template>
  <div class="our_people">
    <section>
      <article>
        <article id="hometop">
          <br /><br /><br /><br /><br />
          <main>
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <h4
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    class="animated slideInUp"
                  >
                    <strong>Our People</strong>
                  </h4>
                  <hr
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    style="background-color: #fafafa; height: 0.0001px"
                  />
                </div>
              </div>
              <br /><br />
              <div class="row">
                <div
                  class="col-lg3 col-md-6 col-sm-6 col-xs-6 mb-5"
                  v-for="leader in leaders"
                  :key="leader.name"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <div>
                    <div class="leader_image">
                      <img :src="leader.imageUrl" />
                    </div>
                    <h5 style="color: black">
                      <strong>{{ leader.name }}</strong>
                    </h5>
                  </div>
                  <p style="color: grey">{{ leader.position }}</p>
                  <el-collapse
                    v-model="leader.activeNames"
                    @change="handleChange"
                  >
                    <el-collapse-item
                      title="See Details"
                      name="0"
                    >
                      <div>{{ leader.about }}</div>
                    </el-collapse-item>
                  </el-collapse>
                </div>
              </div>
              <br />
              <br />
              <!-- <div class="mb-5">
                <h2 style="font-size: 1.2em; font-weight: 600;">Trekkers Tavern Team</h2>
                <hr />
                <img
                  src="../assets/images/team/team2.jpg"
                  width="100%"
                >
              </div> -->

              <br /><br /><br />
            </div>
          </main>
        </article>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeNames: [],
      leaders: [
        {
          activeNames: "1",
          name: "Mr. Mutebi Hassan",
          position: "CEO",
          imageUrl: require("../assets/images/download.png"),
          about:
            "Mr. Mutebi Hassan is the Majority shareholder and Director of Access Uganda Safaris. Having had a passion for Hospitality, he founded Access Uganda Safaris in the quest to deliver outstanding service to the East African market and with years of great international exposure in the global high end tourism, Hassan is driven by a strong vision and a passion for excellence and he leads the team with a strong drive for business growth as well as employee development.",
        },
        {
          activeNames: "2",
          name: "Mr. Mutebi Hassan",
          position: "CEO",
          imageUrl: require("../assets/images/download.png"),
          about:
            "Mr. Mutebi Hassan is the Majority shareholder and Director of Access Uganda Safaris. Having had a passion for Hospitality, he founded Access Uganda Safaris in the quest to deliver outstanding service to the East African market and with years of great international exposure in the global high end tourism, Hassan is driven by a strong vision and a passion for excellence and he leads the team with a strong drive for business growth as well as employee development.",
        },
        {
          activeNames: "3",
          name: "Mr. Mutebi Hassan",
          position: "CEO",
          imageUrl: require("../assets/images/download.png"),
          about:
            "Mr. Mutebi Hassan is the Majority shareholder and Director of Access Uganda Safaris. Having had a passion for Hospitality, he founded Access Uganda Safaris in the quest to deliver outstanding service to the East African market and with years of great international exposure in the global high end tourism, Hassan is driven by a strong vision and a passion for excellence and he leads the team with a strong drive for business growth as well as employee development.",
        },
        {
          activeNames: "4",
          name: "Mr. Mutebi Hassan",
          position: "CEO",
          imageUrl: require("../assets/images/download.png"),
          about:
            "Mr. Mutebi Hassan is the Majority shareholder and Director of Access Uganda Safaris. Having had a passion for Hospitality, he founded Access Uganda Safaris in the quest to deliver outstanding service to the East African market and with years of great international exposure in the global high end tourism, Hassan is driven by a strong vision and a passion for excellence and he leads the team with a strong drive for business growth as well as employee development.",
        },
      ],
    };
  },

  methods: {
    handleChange(val) {
      console.log(val);
    },
  },
};
</script>

<style scoped>
main {
  text-align: left;
}

.leader_image {
  background-color: #c0c4cc;
  height: 400px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leader_image img {
  height: 400px;
  width: 100%;
  object-fit: cover;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
}
</style>

<style>
.our_people .el-collapse-item__header {
  background-color: transparent;
  border-bottom: 1px solid #ebeef5;
  color: #3d290a;
  text-align: left;
}
.our_people .el-collapse-item {
  background-color: transparent;
  color: #3d290a;
  text-align: left;
}
.our_people .el-collapse-item__content {
  border: none;
  background-color: transparent;
  color: #3d290a;
  text-align: left;
}
.our_people .el-collapse-item__wrap {
  will-change: height;
  background-color: transparent;
  overflow: hidden;
  box-sizing: border-box;
  border-bottom: 1px solid #ebeef5;
}
</style>